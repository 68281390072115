<template>
  <div>
    <Dialog
      v-model:visible="showCreateCampaignDialog"
      :draggable="false"
      :modal="true"
      style="width: 80%"
    >
      <template #header>
        <h2 class="fw-normal">Buat Campaign</h2>
      </template>
      <CreateCampaign />
    </Dialog>
    <Button label="Buat Campaign" @click="openModal(`createCampaign`)" />
    <div style="height: 10px" />
    <DataTable :value="campaigns" responsiveLayout="scroll" :scrollable="true">
      <Column
        v-for="col in columns"
        :key="col.field"
        :field="col.field"
        style="min-width: 150px"
      >
        <template #header>
          <span style="font-size: 0.9rem">{{ col.header }}</span>
        </template>
      </Column>
      <Column alignFrozen="right" frozen>
        <template #body>
          <h5 class="p-1" style="cursor: pointer" @click="toggleMenu">
            <i class="fa-solid fa-ellipsis-vertical"></i>
          </h5>
          <Menu ref="menu" :model="menuItems" :popup="true">
            <template #item="{ item }">
              <router-link to="#" :class="item.class" @click="item.atClick">
                {{ item.label }}
              </router-link>
            </template>
          </Menu>
        </template>
      </Column>
      <template #empty>
        <span>Tidak ada data kontak</span>
      </template>
    </DataTable>
  </div>
</template>

<script>
import Button from "primevue/button";
import CreateCampaign from "./CreateCampaign.vue";
export default {
  components: {
    Button,
    CreateCampaign,
  },
  name: "CampaignTable",
  data() {
    return {
      columns: [
        { field: "name", header: "Nama Perusahaan" },
        { field: "pipeline", header: "Pipeline" },
        { field: "industri", header: "Industri" },
        { field: "group", header: "Grup" },
        { field: "contact", header: "Kontak" },
        { field: "existingProduct", header: "Produk Existing" },
        {
          field: "assignedUser",
          header: "Assign User",
        },
        {
          field: "lastFollowUpAction",
          header: "Tindakan Follow Up Terakhir",
        },
        {
          field: "lastFollowUpDate",
          header: "Tanggal Follow Up Terakhir",
        },
      ],
      campaigns: [
        {
          name: "Nama Perusahaan",
          pipeline: "pipeline",
          industri: "industri",
          group: "group",
          contact: "contact",
          existingProduct: "existingProduct",
          assignedUser: "Assign User",
          lastFollowUpAction: "Tindakan Follow Up Terakhir",
          lastFollowUpDate: "Tanggal Follow Up Terakhir",
        },
      ],
      menuItems: [
        {
          label: "Follow Up",
          class: "m-2 mt-4 text-decoration-none text-black d-block",
          atClick: () => {
            this.$emit("follow-up");
          },
        },
        {
          label: "Buat Kontak Baru",
          class: "m-2 text-decoration-none text-black d-block",
          atClick: () => {
            this.$emit("create-new-contact");
          },
        },
        {
          label: "Buat Pipeline Baru",
          class: "m-2 text-decoration-none text-black d-block",
          atClick: () => {
            this.$emit("create-new-pipeline");
          },
        },
        {
          label: "Update Pipeline",
          class: "m-2 text-decoration-none text-black d-block",
          atClick: () => {
            this.$emit("update-pipeline");
          },
        },
        {
          label: "Assign User",
          class: "m-2 text-decoration-none text-black d-block",
          atClick: () => {
            this.$emit("assign-user");
          },
        },
        {
          label: "Detail",
          class: "m-2 text-decoration-none text-black d-block",
          atClick: () => {
            this.$emit("detail");
          },
        },
        {
          label: "Edit",
          class: "m-2 text-decoration-none text-black d-block",
          atClick: () => {
            this.$emit("edit");
          },
        },
        {
          label: "Hapus",
          class: "m-2 my-4 text-decoration-none text-danger d-block",
          atClick: () => {
            this.$emit("delete");
          },
        },
      ],
      showCreateCampaignDialog: false,
    };
  },
  methods: {
    toggleMenu(event) {
      this.$refs.menu.toggle(event);
    },
    openModal(type) {
      switch (type) {
        case "createCampaign":
          this.showCreateCampaignDialog = true;
          break;

        default:
          break;
      }
    },
  },
};
</script>
