import { createRouter, createWebHashHistory } from "vue-router";
import ProdukList from "@/views/Produk/List.vue";
import CampaignView from "../views/Campaign/List.vue";

const routes = [
  {
    path: "/",
    redirect: { name: "dashboard" },
    component: () => import("@/layout/ContentLayout"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard/DashboardMain"),
      },

      {
        path: "/perusahaan/list/new",
        name: "company-list-new",
        component: () => import("@/views/Perusahaan/PerusahaanList_.vue"),
      },

      {
        path: "/perusahaan/detail/new/:id",
        name: "company-detail-new",
        component: () => import("@/views/Perusahaan/PerusahaanDetail_.vue"),
      },
      {
        path: "/customer/detail/:id",
        name: "customer-detail",
        component: () => import("@/views/Database/Customer/CustomerDetail"),
      },
      {
        path: "/customer-contact/detail/:id",
        name: "kontak-customer-detail",
        component: () =>
          import("@/views/Database/Customer/KontakCustomerDetail"),
      },
      {
        path: "/partner/detail/:id",
        name: "partner-detail",
        component: () => import("@/views/Database/Partner/PartnerDetail"),
      },
      {
        path: "/database/customer",
        name: "database-customer",
        component: () => import("@/views/Database/Customer/CustomerMain"),
      },
      {
        path: "/database/customer-contact/detail/:id",
        name: "kontak-customer-detail",
        component: () =>
          import("@/views/Database/Customer/KontakCustomerDetail"),
      },
      {
        path: "/database/partner",
        name: "database-partner",
        component: () => import("@/views/Database/Partner/PartnerMain"),
      },
      {
        path: "/database/partner-contact/detail/:id",
        name: "kontak-partner-detail",
        component: () => import("@/views/Database/Partner/KontakPartnerDetail"),
      },
      {
        path: "/review/sales",
        name: "review-sales",
        component: () => import("@/views/Review/Sales/SalesMain"),
      },
      {
        path: "/review/telemarketing",
        name: "review-telemarketing",
        component: () =>
          import("@/views/Review/Telemarketing/TelemarketingMain"),
      },
      {
        path: "/projects/forecast",
        name: "projects-forecast",
        component: () => import("@/views/Projects/Forecast"),
      },
      {
        path: "/projects/pipeline",
        name: "projects-pipeline",
        component: () => import("@/views/Projects/Pipeline"),
      },
      {
        path: "/projects/leads",
        name: "projects-leads",
        component: () => import("@/views/Projects/LeadProjectList"),
      },
      {
        path: "/projects/existing-customer",
        name: "projects-existing-customer",
        component: () => import("@/views/Projects/ExistingCustomerProject"),
      },
      {
        path: "/kepegawaian/dashboard",
        name: "kepegawaian-dashboard",
        component: () => import("@/views/Kepegawaian/DashboardKepegawaian"),
      },
      {
        path: "/kepegawaian/data-karyawan",
        name: "kepegawaian-data-karyawan",
        component: () =>
          import("@/views/Kepegawaian/DataKaryawan/KaryawanMain"),
      },
      {
        path: "/kepegawaian/data-karyawan/detail/:id",
        name: "kepegawaian-data-karyawan-detail",
        component: () =>
          import("@/views/Kepegawaian/DataKaryawan/DetailKaryawan/DetailMain"),
      },
      {
        path: "/kepegawaian/absensi",
        name: "kepegawaian-absensi",
        component: () => import("@/views/Kepegawaian/Absensi/AbsensiKaryawan"),
      },
      {
        path: "/kepegawaian/absensi/detail/:id",
        name: "kepegawaian-absensi-detail",
        component: () => import("@/views/Kepegawaian/Absensi/DetailAbsensi"),
      },
      {
        path: "/kepegawaian/pengajuan-tidak-masuk",
        name: "kepegawaian-pengajuan-tidak-masuk",
        component: () =>
          import(
            "@/views/Kepegawaian/PengajuanTidakMasuk/PengajuanTidakMasukMain"
          ),
      },
      {
        path: "/kepegawaian/pengajuan-tidak-masuk/detail/:id",
        name: "kepegawaian-pengajuan-tidak-masuk-detail",
        component: () =>
          import(
            "@/views/Kepegawaian/PengajuanTidakMasuk/DetailPengajuan/DetailMain"
          ),
      },
      {
        path: "/pipeline/detail/:id",
        name: "pipeline-detail",
        component: () => import("@/views/Pipeline/PipelineDetail.vue"),
      },
      {
        path: "/produk",
        name: "produk-list",
        component: ProdukList,
      },

      {
        path: "/campaign",
        name: "campaign",
        component: CampaignView,
      },
      {
        path: "/pengaturan/status",
        name: "status",
        component: () => import("@/views/Pengaturan/Status/List.vue"),
      },
      {
        path: "/pengaturan/user",
        name: "user",
        component: () => import("@/views/Pengaturan/User/UserPage.vue"),
      },
      {
        path: "/pengaturan/role/add",
        name: "add-new-role",
        component: () =>
          import("@/components/Pengaturan/User/Role/Modals/AddRole"),
      },
      {
        path: "/pengaturan/role/edit/:id",
        name: "edit-role",
        component: () =>
          import("@/components/Pengaturan/User/Role/Modals/EditRole"),
      },
      {
        path: "/pengaturan/custom-fields",
        name: "custom-fields",
        component: () =>
          import("@/views/Pengaturan/CustomFields/CustomFieldsIndex.vue"),
      },
      {
        path: "/pengaturan/custom-fields/1",
        name: "custom-fields-1-index",
        component: () =>
          import(
            "@/views/Pengaturan/CustomFields/IndustriDataDanJabatan/Index.vue"
          ),
      },
      {
        path: "/pengaturan/custom-fields/2",
        name: "custom-fields-2-index",
        component: () =>
          import("@/views/Pengaturan/CustomFields/StageDanAsalData/Index.vue"),
      },
      {
        path: "/pengaturan/custom-fields/3",
        name: "custom-fields-3-index",
        component: () =>
          import("@/views/Pengaturan/CustomFields/SetTarget/TargetList.vue"),
      },
      {
        path: "/pengaturan/custom-fields/4",
        name: "custom-fields-4-index",
        component: () =>
          import(
            "@/views/Pengaturan/CustomFields/CustomLabelKalender/CustomLabelKalender.vue"
          ),
      },
      {
        path: "/pengaturan/custom-fields/5",
        name: "custom-fields-5-index",
        component: () =>
          import("@/views/Pengaturan/CustomFields/Quadrant/QuadrantList.vue"),
      },
      {
        path: "/pengaturan/custom-fields/6",
        name: "custom-fields-6-index",
        component: () =>
          import(
            "@/views/Pengaturan/CustomFields/StatusDanNextAction/Index.vue"
          ),
      },
      {
        path: "/pengaturan/custom-fields/atribut-pipeline",
        name: "custom-fields-atribut-pipeline",
        component: () =>
          import(
            "@/views/Pengaturan/CustomFields/AtributPipeline/AtributPipeline.vue"
          ),
      },
      {
        path: "/request-demo/detail/:id",
        name: "request-demo-detail",
        component: () => import("@/views/RequestDemo/RequestDemoDetail.vue"),
      },
      {
        path: "/log",
        name: "log-list",
        component: () => import("@/views/Log/LogList.vue"),
      },
      {
        path: "/existing-project",
        name: "existing-project-list",
        component: () =>
          import("@/views/ExistingProject/ExistingProjectList.vue"),
      },
      {
        path: "/existing-project/detail/:id",
        name: "existing-project-detail",
        component: () =>
          import("@/views/ExistingProject/ExistingProjectDetail.vue"),
      },
      {
        path: "/kalender",
        name: "calendar",
        component: () => import("@/views/Kalender/KalenderView.vue"),
      },
      {
        path: "/kalender/detail/:id",
        name: "calendar-detail",
        component: () => import("@/views/Kalender/KalenderDetail.vue"),
      },
    ],
  },
  {
    path: "/login",
    // redirect: "/login",
    name: "login",
    component: () => import("@/views/Login"),
    meta: { requiresUnauth: true },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to) => {
  // instead of having to check every route record with
  // to.matched.some(record => record.meta.requiresAuth)
  if (to.meta.requiresAuth) {
    const user = JSON.parse(localStorage.getItem("user"));

    if (!user || !user.expired || user.expired < new Date().getTime()) {
      return {
        path: "/login",
        // save the location we were at to come back later
        // query: { redirect: to.fullPath },
      };
    }
  }
});

export default router;
