<template>
  <form>
    <div class="form-group mb-4">
      <label for="campaign-type" class="fw-bold mb-2">Jenis Campaign</label>
      <select name="campaign-type" class="form-select rounded-0">
        <option
          v-for="s in campaignTypes"
          :key="s.value"
          :value="s.value"
          :disabled="s.disabled"
          :selected="s.selected"
        >
          {{ s.name }}
        </option>
      </select>
    </div>
    <div class="form-group mb-4">
      <label for="products" class="fw-bold mb-2">Product</label>
      <select name="campaign-type" class="form-select rounded-0">
        <option v-for="s in products" :key="s.value" :value="s.value">
          {{ s.products }}
        </option>
      </select>
    </div>
    <div class="form-group mb-4">
      <label for="nama" class="fw-bold mb-2">Nama Prospek</label>
      <input type="text" name="nama" class="form-control rounded-0" />
    </div>
    <button
      type="submit"
      class="btn btn-light rounded-0 border d-block ms-auto"
    >
      Submit
    </button>
  </form>
</template>

<script>
export default {
  data() {
    return {
      // Select Option
      campaignTypes: [
        { name: "Pilih", value: null, disabled: true, selected: true },
        { name: "Webinar", value: "webinar" },
        {
          name: "Promo",
          value: "promo",
        },
      ],
      products: [],
    };
  },
};
</script>

<style></style>
