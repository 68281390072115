<template>
  <div>
    <CampaignTable />
  </div>
</template>

<script>
import CampaignTable from "@/components/Campaign/CampaignTable.vue";

export default {
  name: "CampaignView",
  components: {
    CampaignTable,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
